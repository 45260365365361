import { SUPER_ADMIN_ROLE } from "~/constants/advisor";
import { ORG_PERMISSIONS } from "~/constants/orgPermissions";

export default defineNuxtRouteMiddleware(async (to) => {
  const { $orgPermissions, $auth } = useNuxtApp();
  const $router = useRouter();

  const isUserSuperAdmin = $auth.user?.user_type === SUPER_ADMIN_ROLE;

  if (!(await $orgPermissions.hasPermission(to, ORG_PERMISSIONS.ADVISOR)) && !isUserSuperAdmin) {
    $router.replace({
      path: "/",
    });
  }
});
